<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="元数据表：" prop="metadataTable">
                <el-select v-model="inputForm.metadataTable" @change="metadataTableChange" placeholder="请选择元数据表" style="width: 100%">
                    <el-option
                            v-for="item in  $dictUtils.getDictList('table_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="著录项字段名：" prop="recordingItem">
                <el-select v-model="inputForm.recordingItem"  @change="commentChange" placeholder="请选择著录项字段名" style="width: 100%">
                    <el-option
                            v-for="item in fieldNameOptions"
                            :key="item.field"
                            :label="item.comment"
                            :value="item.field">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="著录项名：" prop="recordName">
                <el-input v-model.trim="inputForm.recordName" placeholder="请输入著录项名" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="前连接符：" prop="frontConnector">
                <el-input v-model="inputForm.frontConnector" placeholder="请输入前连接符" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="porder">
                <el-input-number v-model="inputForm.porder" :min="1"></el-input-number>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    rulesName: '',
                    recordingItem: '',
                    frontConnector: '',
                    porder:1,
                    metadataTable:"",
                    recordName:""
                },
                fieldNameOptions:[],
                dataRule: {
                    metadataTable: [
                        {required: true, message: '元数据表不能为空', trigger: 'blur'}
                    ],
                    recordingItem: [
                        {required: true, message: '著录字段名不能为空', trigger: 'change'}
                    ],
                    recordName: [
                        {required: true, message: '著录名称不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                this.inputForm.id = ""
                if (method === 'add') {
                    this.title = `新建档号规则`
                    this.inputForm.rulesName = row
                } else if (method === 'edit') {
                    this.title = '编辑档号规则'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            metadataTableChange(val){
                this.inputForm.recordingItem = ""
                this.inputForm.recordName = ""
                this.$axios(this.api.catalogue.tableDate, {flag:val}, 'get').then(data => {
                    if (data && data.status) {
                        this.fieldNameOptions = data.data
                    }
                })
            },
            commentChange(val){
                let that = this;
                this.fieldNameOptions.forEach(function (v) {
                    if(val == v.field){
                        that.inputForm.recordName = v.comment
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.title==`新建档号规则`){
                            this.$axios(this.api.catalogue.rulesSave, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error("著录项已存在")
                                }
                            })
                        }else{
                            this.$axios(this.api.catalogue.rulesUpdateById, this.inputForm, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error("著录项已存在")
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
